<template>
  <div class="bg-gray-200">
    <div class="container mx-auto grid grid-cols-2 w-full">
      <div class="py-2 text-md">
        <router-link to="/" class="text-green hover:underline"
          >Dashboard</router-link
        >
        <span class="text-gray-400"> / </span>
        <span class="font-bold">Commissions</span>
      </div>
    </div>
  </div>

  <div class="container mx-auto py-5">
    <h1 class="text-xl font-semibold text-gray-900">Summary</h1>
    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
      <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
        <dt class="truncate text-sm font-medium text-gray-500">Unpaid</dt>
        <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
          $ {{ commissions_summary.unpaid_total }}
        </dd>
      </div>

      <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
        <dt class="truncate text-sm font-medium text-gray-500">Paid</dt>
        <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
          $ {{ commissions_summary.paid_total }}
        </dd>
      </div>

      <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
        <dt class="truncate text-sm font-medium text-gray-500">Deductibles</dt>
        <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
          $ {{ commissions_summary.deductibles_total }}
        </dd>
      </div>

      <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
        <dt class="truncate text-sm font-medium text-gray-500">Total</dt>
        <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
          $ {{ commissions_summary.total }}
        </dd>
      </div>
    </dl>
  </div>

  <div class="container mx-auto py-5">
    <h1 class="text-xl font-semibold text-gray-900">Rankings</h1>
    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
      <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
        <dt class="truncate text-sm font-medium text-gray-500">
          Last Period ({{ commissions_ranking.last_period.period }})
        </dt>
        <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
          {{ commissions_ranking.last_period.ranking_description }}
        </dd>
      </div>

      <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
        <dt class="truncate text-sm font-medium text-gray-500">
          Current Year ({{ commissions_ranking.current_year.period }})
        </dt>
        <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
          {{ commissions_ranking.current_year.ranking_description }}
        </dd>
      </div>
    </dl>
  </div>

  <div class="container mx-auto px-4 sm:px-0 mt-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">Monthly Breakdown</h1>
      </div>
    </div>
    <div class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div
            class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Year
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Month
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 text-right"
                  >
                    Amount
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 text-right"
                  >
                    Deductibles
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 text-right"
                  >
                    Sales
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 text-right"
                  >
                    Total
                  </th>
                  <th
                    scope="col"
                    class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6"
                  ></th>
                </tr>
              </thead>
              <tbody
                class="divide-y divide-gray-200 bg-white"
                v-if="commissions_report.summary_by_year_and_month.length > 0"
              >
                <template
                  v-for="(
                    item, index
                  ) in commissions_report.summary_by_year_and_month"
                  :key="index"
                >
                  <tr>
                    <td
                      class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6"
                    >
                      {{ formatDate(item.starts_at, "yyyy") }}
                    </td>
                    <td
                      class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900"
                    >
                      {{ formatDate(item.starts_at, "MMMM") }}
                    </td>
                    <td
                      class="whitespace-nowrap px-2 py-2 text-sm text-gray-900 text-right"
                    >
                      $ {{ item.amount }}
                    </td>
                    <td
                      class="whitespace-nowrap px-2 py-2 text-sm text-gray-900 text-right"
                    >
                      $ {{ item.deductibles }}
                    </td>
                    <td
                      class="whitespace-nowrap px-2 py-2 text-sm text-gray-900 text-right"
                    >
                      $ {{ item.sales_total }}
                    </td>
                    <td
                      class="whitespace-nowrap px-2 py-2 text-sm text-gray-900 text-right"
                    >
                      $ {{ item.total }}
                    </td>
                    <td
                      class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
                    >
                      <button
                        class="text-indigo-600 hover:text-indigo-900"
                        @click.prevent="exportReportItem(item)"
                      >
                        Export
                      </button>
                    </td>
                  </tr>
                </template>
                <tr>
                  <th
                    class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6 text-left"
                  >
                    Total
                  </th>
                  <th
                    class="whitespace-nowrap px-2 py-2 text-sm text-gray-900"
                  ></th>
                  <th
                    class="whitespace-nowrap px-2 py-2 text-sm text-gray-900 text-right"
                  >
                    $ {{ commissions_report.commissions_total }}
                  </th>
                  <th
                    class="whitespace-nowrap px-2 py-2 text-sm text-gray-900 text-right"
                  >
                    $ {{ commissions_report.deductibles_total }}
                  </th>
                  <th
                    class="whitespace-nowrap px-2 py-2 text-sm text-gray-900 text-right"
                  >
                    $ {{ commissions_report.sales_total }}
                  </th>
                  <th
                    class="whitespace-nowrap px-2 py-2 text-sm text-gray-900 text-right"
                  >
                    $ {{ commissions_report.total }}
                  </th>
                  <th
                    class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
                  ></th>
                </tr>
              </tbody>
              <tbody
                class="divide-y divide-gray-200 bg-white"
                v-if="commissions_report.summary_by_year_and_month.length === 0"
              >
                <tr>
                  <td
                    colspan="7"
                    class="whitespace-nowrap text-sm text-gray-500 sm:pl-6 text-center p-8"
                  >
                    No data found
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";

export default {
  data: () => {
    return {
      rep_company: {},
      commissions_summary: {},
      commissions_report: { summary_by_year_and_month: [] },
      commissions_ranking: { last_period: {}, current_year: {} },
    };
  },

  async mounted() {
    document.title = "MLE Rep Center | Commissions";
    await this.getMyCommissions();
  },

  methods: {
    async getMyCommissions() {
      const resp = await axios.get("api/rep-center/me/commissions");
      this.rep_company = resp.data.rep_company;
      this.commissions_summary = resp.data.commissions_summary;
      this.commissions_report = resp.data.commissions_report;
      this.commissions_ranking = resp.data.commissions_ranking;
    },

    formatDate(dateString, format) {
      return moment.utc(dateString).format(format);
    },

    async exportReportItem(reportItem) {
      const resp = await await axios.get(
        `api/rep-center/me/commissions/${reportItem.year}/${reportItem.month}/excel`
      );
      window.open(resp.data.url, "_blank");
    },
  },
};
</script>
